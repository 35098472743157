const isOlderThanFiveMinutes = (date) => {
  const timestamp = new Date(date);
  const currentTime = new Date();
  const currentTimeUtc = new Date(currentTime.toISOString());
  const timeDifference = currentTimeUtc - timestamp;
  const res = timeDifference >= 5 * 60 * 1000;
  return res;
};

export default isOlderThanFiveMinutes;
