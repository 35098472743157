import Money from 'components/global/Money';
import { StatusBadge } from 'components/global/StatusPills/StatusPills';
import { handleInvoiceClickOpen } from 'components/views/Customers/_helpers/Customers.helpers';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { $invoicesUI } from 'components/views/Invoices/Invoices.helpers';

export function setInvoiceTableHeaders() {
  const headers = [
    {
      sortKey: 'customer_name',
      key: 'Name',
      value: 'Name',
    },
    {
      sortKey: 'invoice_date',
      key: 'InvoiceDate',
      value: 'Invoice Date',
    },
    {
      sortKey: 'due_date',
      key: 'DueDate',
      value: 'Due Date',
    },
    {
      sortKey: 'number',
      key: 'Number',
      value: 'Number',
    },
    {
      sortKey: 'latest_acct_provider_status',
      key: 'Status',
      value: 'Status',
    },
    {
      sortKey: 'total_amount',
      key: 'Amount',
      value: 'Amount',
    },
    {
      sortKey: 'latest_acct_provider_balance',
      key: 'Balance',
      value: 'Balance',
    },
    {
      sortKey: 'email_sent_at',
      key: 'Delivery',
      value: 'Delivery',
    },
    {
      key: 'View',
      value: 'View',
    },
  ];
  $invoicesUI.update({ tableHeaders: headers });
}

export function getInvoiceTableRows(items = []) {
  const rows = items.map((item) => ({
    id: item.id,
    Name: item.organization_customer.name,
    InvoiceDate: () => (
      <>{moment(item.invoice_date).format('MM/DD/YYYY')}</>
    ),
    DueDate: () => (
      <>{moment(item.due_date).format('MM/DD/YYYY')}</>
    ),
    Number: item.number,
    Status: () => {
      if (!item.last_sync_at) return <span />;
      if (item.latest_acct_provider_status === 'PAID') {
        return (<StatusBadge status="paid" className="border" />);
      }
      if (moment(item.due_date) < moment()) {
        return (<StatusBadge status="open" isOverdue />);
      }
      return (<StatusBadge status="open" className="border" />);
    },
    Amount: () => (
      <span><Money amount={item.amount} /></span>
    ),
    Balance: () => (
      <span className="fw-700"><Money amount={item.latest_acct_provider_balance} /></span>
    ),
    Delivery: () => {
      const className = item.email_sent_at ? 'text-primary' : 'text-danger';
      const value = item.email_sent_at
        ? `Sent ${moment(item.email_sent_at).format('MM/DD/YYYY')}`
        : 'Unsent';

      return (
        <span className={className}>{value}</span>
      );
    },
    View: () => (
      <Button
        type="button"
        variant="link"
        className="p-0"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleInvoiceClickOpen({ invoice: item });
        }}
      >
        View
      </Button>
    ),
    data: item,
  }));

  return rows;
}
