export const getConfig = (key) => {
  const env = process.env.REACT_APP_ENV || 'production';

  const base = {
    ENV: env,
    BACKEND_GRAPHQL_ENDPOINT: process.env.REACT_APP_BACKEND_GRAPHQL_ENDPOINT,
    REACT_APP_FIREBASE_CONFIG: {
      apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_CONFIG_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_CONFIG_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENT_ID,
    },
  };

  const development = {
    ...base,
  };

  const configSettings = {
    development,
  };

  const config = configSettings[env] || configSettings.development;

  if (!key) {
    return config;
  }

  return config[key];
};

export default {
  getConfig,
};
