import { Image, InputGroup } from 'react-bootstrap';
import UniversalInput from 'components/global/Inputs/UniversalInput';
import magnifyingImg from 'images/magnifying.svg';
import closeButtonImg from 'images/closeButton.svg';

import { handleSelectAdminOrganizationSearchClear } from 'components/views/SelectAdminOrganization/SelectAdminOrganization.helpers';
import $selectAdminOrganizationDetail from 'components/views/SelectAdminOrganization/SelectAdminOrganization.signals';

const SelectAdminOrganizationSearch = () => (
  <InputGroup
    className="py-8"
  >
    <InputGroup.Text
      className="ps-24 border-0"
      style={{
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderTopLeftRadius: 100,
        borderBottomLeftRadius: 100,
      }}
    >
      <Image src={magnifyingImg} />
    </InputGroup.Text>

    <UniversalInput
      name="search"
      signal={$selectAdminOrganizationDetail}
      className="custom-searchbox flex-grow-1"
      autoComplete="off"
      placeholder="Search Organizations"
    />
    <InputGroup.Text
      className="ps-24 border-0"
      style={{
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
      }}
      onClick={handleSelectAdminOrganizationSearchClear}
    >
      {$selectAdminOrganizationDetail.value.search && (<Image src={closeButtonImg} />)}
    </InputGroup.Text>
  </InputGroup>
);

export default SelectAdminOrganizationSearch;
